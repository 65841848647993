/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Helmet } from "react-helmet";
import { graphql, navigate } from "gatsby";
import { useRecoilState, useResetRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { isNull, uniq, findIndex } from "lodash";

import {
  spidStepAtom,
  selectedItemAtom,
  itemAtom,
  spidItemAtom,
  cartAtom,
  showCartAtom,
} from "../utils/atoms";
import { priceFromIdSelector } from "../utils/selectors"
import { useShopifyFunctions } from "../utils/useShopifyFunctions";

import Layout from "../layout";
import MenuNav from "../components/MenuNav/MenuNav";
import Summary from "../components/Summary/Summary";
import Choice from "../components/Choice/Choice";
import Canvas from "../components/Canvas/Canvas";
import Patterns from "../components/Patterns/Patterns";
import Spid from "../components/Spid/Spid";
import Extras from "../components/Extras/Extras";
import Sizes from "../components/Sizes/Sizes";

const stepOrder = [
  { label: "Pattern", value: "spid-pattern" },
  { label: "Size", value: "spid-size" },
  { label: "ID Tag", value: "spid-spid" },
  { label: "Extras", value: "spid-extras" },
  { label: "Summary", value: "spid-summary" },
];

function SpidCustom({ location, data }) {
  const { shopifyId, variants, title } = data.shopifyProduct;
  const { addItem, getCheckout } = useShopifyFunctions();

  const [selectedItem, setSelectedItem] = useRecoilState(selectedItemAtom);
  const resetSelected = useResetRecoilState(selectedItemAtom);
  const [item, setItem] = useRecoilState(itemAtom(selectedItem));
  const [spidItem, setSpidItem] = useRecoilState(spidItemAtom(selectedItem));
  const [cart, setCart] = useRecoilState(cartAtom);
  const price = useRecoilValue(priceFromIdSelector({ id: selectedItem }));
  const showCart = useSetRecoilState(showCartAtom);


  if (!selectedItem || isNull(selectedItem)) {
    setSelectedItem(`sp-${uuidv4()}`);
  }

  const [spidStep, setSpidStep] = useRecoilState(spidStepAtom);

  const addToCartHandler = () => {
    setCart(uniq([...cart, selectedItem]));
    showCart(true);
  };

  return (
    <Layout 
      location={location} 
      back={() => {
        navigate("/spid-grid");
      }}
    >
      <>
        <Helmet title="Stuntpuppy" />
        <div className="flex h-screen">
          <div className="w-2/3 relative bg-gradient-radial from-white to-sp-md-gray">
            <Canvas />
            {/* <iframe
              className="w-full h-full"
              src="https://sp-vis-r.netlify.app/"
              title="visualizer"
            /> */}
            <div className="absolute right-0 top-0 bg-gradient-to-l from-black to-transparent h-full w-24 opacity-10	" />
            {cart.includes(selectedItem) && <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-8 bg-white py-2 px-8">
                You are editing an item already in your cart
            </div>}
          </div>

          <div className="w-1/3 h-screen relative border-black bg-white overflow-auto flex flex-col">
            {/* <div className="absolute bottom-0 mb-32 bg-gradient-to-t from-gray-300 to-white w-full h-16" /> */}
          <div
              className="relative h-full overflow-auto">
              {spidStep === "spid-type" && (
                <div className="h-full mx-4 rounded-lg bg-white flex flex-col justify-center">
                  <h2 className="text-2xl font-medium my-2 text-center absolute top-0 left-0 right-0 mt-8">
                    Select Product
                  </h2>
                  <Choice
                    value="collar"
                    name="product"
                    text="Collar"
                    checked={spidItem.product === "collar"}
                    onChange={(e) =>
                      setSpidItem({ ...spidItem, product: e.target.value })
                    }
                  />
                  <Choice
                    value="leash"
                    name="product"
                    text="Leash"
                    checked={spidItem.product === "leash"}
                    onChange={(e) =>
                      setSpidItem({ ...spidItem, product: e.target.value })
                    }
                  />
                  <Choice
                    value="bundle"
                    name="product"
                    text="Bundle"
                    checked={spidItem.product === "bundle"}
                    onChange={(e) =>
                      setSpidItem({ ...spidItem, product: e.target.value })
                    }
                  />
                </div>
              )}

              {spidStep === "spid-pattern" && <Patterns />}

              {spidStep === "spid-size" && (
                <Sizes item={spidItem} set={setSpidItem} />
              )}

              {spidStep === "spid-spid" && (
                <Spid item={spidItem} set={setSpidItem} />
              )}
              {spidStep === "spid-extras" && (
                <Extras product="spid" item={spidItem} set={setSpidItem} />
              )}
              {spidStep === "spid-summary" && (
                <Summary product="spid" item={spidItem} set={setSpidItem} />
              )}
            </div>

            <div className="relative bg-sp-gray w-full flex items-end h-32 p-8 pt-4">
              <MenuNav set={setSpidStep} current={spidStep} steps={stepOrder} />
              <div className="flex flex-col items-center w-1/2">
                <div className="text-lg">${price}.00</div>
              {findIndex(stepOrder, {value: spidStep}) < stepOrder.length - 1 ? (
                  <button
                    type="button"
                    className="w-full bg-sp-orange text-xl font-medium rounded-full text-white mt-2 px-8 py-2 ml-2"
                    onClick={() => setSpidStep(stepOrder[1 + findIndex(stepOrder, {value: spidStep})].value)}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    className="w-full bg-sp-orange text-xl font-medium rounded-full text-white mt-2 px-8 py-2 ml-2"
                    onClick={addToCartHandler}
                  >
                    Add to Cart
                  </button>
                )}
              </div>
            </div>

          </div>
        </div>
      </>
    </Layout>
  );
}

export const query = graphql`
  {
    shopifyProduct(shopifyId: {eq: "gid://shopify/Product/6573303758946"}) {
      shopifyId
      title
      options {
        name
        values
      }
      variants {
        title
        shopifyId
      }
      description
    }
  }
`;

export default SpidCustom;
