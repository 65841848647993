import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useRecoilState, useRecoilValue } from "recoil";
import { reduce, includes, union, filter } from "lodash";

import {
  CustomCheckbox,
  CustomCheckboxContainer,
  CustomCheckboxInput,
} from "@reach/checkbox";
import "@reach/checkbox/styles.css";

import StepWrap from "../StepWrap/StepWrap";
import Choice from "../Choice/Choice";
import SpidImg from "../SpidImg/SpidImg";

import { selectedItemAtom, spidItemAtom } from "../../utils/atoms";

const Patterns = () => {
  const selectedItem = useRecoilValue(selectedItemAtom);
  const [spidItem, setSpidItem] = useRecoilState(spidItemAtom(selectedItem));

  const data = useStaticQuery(graphql`
    query PatternsQuery {
      patternList: allAirtable(
        sort: { fields: rowIndex }
        filter: { table: { eq: "Patterns" }, data: { Active: { eq: true } } }
      ) {
        edges {
          node {
            id
            data {
              name: Name
              value: Value
              backgroundColor: Background_Color
              textColor: Text_Color
              tags: Tags
              colors: ColorFamily
              file: File {
                localFiles {
                  id
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `);

const [selectedCategory, setSelectedCategory] = useState("");
const [selectedColor, setSelectedColor] = useState("");
const [filteredList, setFilteredList] = useState(data.patternList.edges);
const allTags = reduce(data.patternList.edges, (result, {node: { data: { tags }}}) => union(result, tags), [])
const allColors = reduce(data.patternList.edges, (result, {node: { data: { colors }}}) => union(result, colors), [])

useEffect(() => {
  const everything = data.patternList.edges
  const filtered = filter(everything, ({ node: { data: { colors, tags } } }) => { 
    if ( selectedColor && selectedCategory ) return includes(colors, selectedColor) && includes(tags, selectedCategory)
    if ( selectedColor )  return includes(colors, selectedColor)
    if ( selectedCategory )  return includes(tags, selectedCategory)
    return true; 
  });
  setFilteredList(filtered)
}, [data.patternList.edges, selectedCategory, selectedColor]);



  return (
    <StepWrap title="Select a Pattern">
      <div className="flex flex-col items-center mb-8 text-xl max-w-3xl">
        <div className="flex flex-col w-full mb-4">
            <span className="uppercase font-bold text-sm mb-2">Category</span>
            <select 
              name="category" 
              id="category"
              className="rounded-full bg-sp-gray border-none mr-8 text-xl w-full"
              value={selectedCategory}
              onChange={e => setSelectedCategory(e.currentTarget.value)}
            >
              <option value="">All</option>
              {allTags.map( tag => <option value={tag}>{tag}</option>)}
            </select>
          </div>
          <div className="flex flex-col w-full">
            <span className="uppercase font-bold text-sm mb-2">Color</span>
            <select 
              name="color" 
              id="color"
              className="rounded-full bg-sp-gray border-none text-xl w-full"
              value={selectedColor}
              onChange={e => setSelectedColor(e.currentTarget.value)}
            >
              <option value="">All</option>
              {allColors.map( color => <option value={color}>{color}</option>)}
            </select>
          </div>
      </div>
      {filteredList.map(
        ({
          node: {
            id,
            data: { name, value, file, textColor, backgroundColor },
          },
        }) => (
          <div
            className="w-full"
            key={id}
          >
            <Choice
              value={value}
              name="pattern"
              text={name}
              checked={spidItem.pattern.value === value}
              onChange={() =>
                setSpidItem({
                  ...spidItem,
                  textColor,
                  backgroundColor,
                  pattern: {
                    label: name,
                    value,
                    img: file.localFiles[0].publicURL,
                  },
                })
              }
            >
              <div className="h-20 w-full relative ml-4">
              {typeof window === "object" && file.localFiles[0].publicURL && <SpidImg     
                sz={1}
                pattern={file.localFiles[0].publicURL}
                placeholder=""
                size="5rem"
              />}
              </div>
            </Choice>
          </div>
        )
      )}
    </StepWrap>
  );
};

export default Patterns;
