import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Switch } from "@headlessui/react";

import { selectedItemAtom, spidItemAtom } from "../../utils/atoms";
import StepWrap from "../StepWrap/StepWrap";
import Input from "../Input/Input";

const Spid = () => {
  const selectedItem = useRecoilValue(selectedItemAtom);
  const [spidItem, setSpidItem] = useRecoilState(spidItemAtom(selectedItem));

  return (
    <StepWrap title="Add your pet's ID info">
      <div className="h-full overflow-auto mx-4 flex flex-col justify-center">
        <Switch.Group>
          <div className="flex items-center">
            <Switch
              checked={spidItem.includeSpid}
              onChange={(e) => setSpidItem({ ...spidItem, includeSpid: e })}
              className={`${
                spidItem.includeSpid ? "bg-sp-orange" : "bg-gray-200"
              } relative inline-flex items-center h-6 rounded-full w-11`}
            >
              <span className="sr-only">Include tag info</span>
              <span
                className={`${
                  spidItem.includeSpid ? "translate-x-6" : "translate-x-1"
                } inline-block w-4 h-4 transform bg-white rounded-full`}
              />
            </Switch>
            <Switch.Label className="ml-2">Include ID info</Switch.Label>
          </div>
        </Switch.Group>

        <br />

        <Input
          label="Line 1"
          name="line1"
          placeholder="example: Pet Name"
          type="text"
          value={spidItem.spid[0]}
          onChange={(e) => {
            const copy = [...spidItem.spid];
            copy[0] = e.target.value;
            setSpidItem({ ...spidItem, spid: copy });
          }}
        />
        <Input
          label="Line 2"
          name="line2"
          placeholder="example: Owner Name"
          type="text"
          value={spidItem.spid[1]}
          onChange={(e) => {
            const copy = [...spidItem.spid];
            copy[1] = e.target.value;
            setSpidItem({ ...spidItem, spid: copy });
          }}
        />
        <Input
          label="Line 3"
          name="line3"
          placeholder="example: Phone"
          type="text"
          value={spidItem.spid[2]}
          onChange={(e) => {
            const copy = [...spidItem.spid];
            copy[2] = e.target.value;
            setSpidItem({ ...spidItem, spid: copy });
          }}
        />
        <Input
          label="Line 4"
          name="line4"
          placeholder="example: Address"
          type="text"
          value={spidItem.spid[3]}
          onChange={(e) => {
            const copy = [...spidItem.spid];
            copy[3] = e.target.value;
            setSpidItem({ ...spidItem, spid: copy });
          }}
        />
        <Input
          label="Line 5"
          name="line5"
          placeholder="example: City/State"
          type="text"
          value={spidItem.spid[4]}
          onChange={(e) => {
            const copy = [...spidItem.spid];
            copy[4] = e.target.value;
            setSpidItem({ ...spidItem, spid: copy });
          }}
        />
      </div>
    </StepWrap>
  );
};

export default Spid;
